<template>
  <div class="">
      <ul class="flex items-center -space-x-px h-8 text-base">
        <li>
          <button :disabled="currentPage - 1 == 0" :class="{'cursor-not-allowed': currentPage - 1 == 0}" type="button" @click.prevent="getPage(currentPage - 1)" class="flex items-center justify-center w-8	h-8 p-2.5 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-md">
            <span class="sr-only">Previous</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
              <path d="M0.639227 5.81373C0.0808775 5.41491 0.0808775 4.58509 0.639227 4.18627L4.91876 1.12946C5.58063 0.656692 6.5 1.12982 6.5 1.94319L6.5 8.05681C6.5 8.87018 5.58063 9.34331 4.91876 8.87054L0.639227 5.81373Z" fill="#757A8A"/>
            </svg>
          </button>
        </li>

        <li v-for="pageNumber in totalPagesNumber" >
                <span v-if="(Math.abs(pageNumber - currentPage) < 3 || pageNumber == totalPagesNumber - 1 || pageNumber == 0 || pageNumber == 1 || pageNumber == 2 || pageNumber == 3)">
                        <template v-if="(pageNumber == 3 && Math.abs(currentPage - pageNumber) > 3)">
                            <a href="javascript:;" class="w-8 h-8 mr-[0.85rem] flex items-center justify-center px-2.5 leading-none font-poppins text-[0.875rem] text-gray-500 border-t border-b border-gray-300" >...</a>
                        </template>
                        <template v-else>
                            <div class="flex items-center justify-between">
                              <a href="javascript:;" class="w-8 h-8 ml-[0.85rem] flex items-center justify-center px-2.5 leading-none font-poppins text-[0.875rem] text-[#3C4549] border-t border-b border-gray-300" v-if="(pageNumber == totalPagesNumber - 1 &&
                                                            Math.abs(pageNumber - currentPage) > 3)">...</a>
                            <a href="javascript:;"  @click.prevent="getPage(pageNumber)"
                               class="w-8 h-8 flex items-center justify-center px-2.5 leading-none font-poppins text-[0.875rem] text-[#3C4549] border border-gray-300"
                               :class="{'text-white bg-[#0165E1]': currentPage == pageNumber,
                            last: (pageNumber == totalPagesNumber - 1 && Math.abs(pageNumber - currentPage) > 3),
                            first:(pageNumber == 0 && Math.abs(pageNumber - currentPage) > 3)}">{{pageNumber}}</a>
                            </div>
                        </template>
               </span>
         </li>

        <li>
          <button :disabled="currentPage == totalPagesNumber" :class="{'cursor-not-allowed': currentPage == totalPagesNumber}" type="button" @click.prevent="getPage(currentPage + 1)" class="flex items-center justify-center p-2.5 w-8	h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-md">
            <span class="sr-only">Next</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
              <path d="M6.36077 5.81373C6.91912 5.41491 6.91912 4.58509 6.36077 4.18627L2.08124 1.12946C1.41937 0.656692 0.5 1.12982 0.5 1.94319L0.5 8.05681C0.5 8.87018 1.41937 9.34331 2.08124 8.87054L6.36077 5.81373Z" fill="#757A8A"/>
            </svg>
          </button>
        </li>
      </ul>
  </div>
</template>

<script>
export default ({
  'name': 'TablePagination',
  props: {
    'resultCount': {
      default: ''
    },
    'itemsPerPage': {
      default: ''
    },
    'currentPage': {
      default: ''
    },
    'totalPagesNumber': {
      default: 0
    },
    'clickedMethod': {
      type: Function
    },
    'data': {
      default: null
    }
  },
  data: function () {
    return {}
  },
  methods: {
    getPage (page) {

      if(this.data) {
        return this.clickedMethod(page, this.data)
      }

      this.clickedMethod(page)
    }

  }
})
</script>
